<template>
  <div class="dabox">
    <!-- 文字标识 -->
    <div class="dingwei1">
      <p class="famen famen1">HV457</p>
      <p class="famen famen2">HV430</p>
      <p class="famen famen3">HV459</p>
      <p class="famen famen4">HV456</p>
      <p class="famen famen5">HV419</p>
      <p class="famen famen6">FV405</p>
      <p class="famen famen7">HV420</p>
      <p class="famen famen8">HV406</p>
      <p class="famen famen9">HV407</p>
      <p class="famen famen10">HV405</p>
      <p class="famen famen11">HV429</p>
      <p class="famen famen12">HV428</p>
      <p class="famen famen13">HV451</p>
      <p class="famen famen14">HV458</p>
      <p class="famen famen15">SV411</p>
      <p class="famen famen16">HV431</p>
      <p class="biaoshi bai biaoshi1">E401</p>
      <p class="biaoshi bai biaoshi2">V405</p>
      <p class="biaoshi bai biaoshi3">P402</p>
      <p class="biaoshi hei biaoshi4">V402</p>
      <p class="biaoshi hei biaoshi5">V403</p>
      <p class="wenzi wenzi1">自公用水箱</p>
      <p class="wenzi wenzi2">蒸汽冷凝器</p>
      <p class="wenzi wenzi3">电动真空泵</p>
      <p class="wenzi wenzi4">电加热</p>
      <p class="wenzi wenzi5">至精馏</p>
      <p class="wenzi wenzi6">放空阀</p>
      <p class="wenzi wenzi7">至放空管</p>
      <p class="wenzi wenzi8">2#原料罐</p>
      <p class="wenzi wenzi9">中间罐</p>
      <p class="wenzi wenzi10">搅拌器</p>
      <p class="wenzi wenzi11">蒸馏柱</p>
      <p class="wenzi fen wenzi12">浓度 / 酸值</p>
      <!-- <p class="shuzi shuzi1">6.36 L/h</p> -->
      <p
        class="shuzi shuzi2"
        @click="
          toCompon(
            2,
            'FV405_MV',
            'DB1S',
            'FV405_MV',
            '冷凝器进水气动调节阀跟踪'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.FV405_MV, 'FV405_MV', 'DB1S', 'FV405_MV')
        "
      >
        {{ infoList.DB1S.FV405_MV }} %
      </p>
      <p class="shuzi shuzi3" @click="
          toCompon(
            2,
            'TI405',
            'DB1S',
            'TI405',
            '冷凝器冷却料液出口温度'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.TI405, 'TI405', 'DB1S', 'TI405')
        "
      >
        {{ infoList.DB1S.TI405 }} ℃</p>
      <p class="shuzi shuzi4">F=0.26㎡</p>
      <p class="shuzi shuzi5" @click="
          toCompon(
            2,
            'TI401',
            'DB1S',
            'TI401',
            '2#原料罐温度'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.TI401, 'TI401', 'DB1S', 'TI401')
        "
      >
        {{ infoList.DB1S.TI401 }} ℃</p>
      <p class="shuzi shuzi6" @click="
          toCompon(
            2,
            'TZ401_MV',
            'DB1S',
            'TZ401_MV',
            '2#原料罐加热器跟踪'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.TZ401_MV, 'TZ401_MV', 'DB1S', 'TZ401_MV')
        "
      >
        {{ infoList.DB1S.TZ401_MV }} %</p>
      <p class="shuzi shuzi7" @click="
          toCompon(
            2,
            'TZ401_MV',
            'DB1S',
            'TZ401_MV',
            '2#原料罐加热器跟踪'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.TZ401_MV, 'TZ401_MV', 'DB1S', 'TZ401_MV')
        "
      >
        {{ infoList.DB1S.TZ401_MV }} %</p>
      <p class="shuzi fen shuzi8" @click="
          toCompon(
            2,
            'AN100',
            'DB1S',
            'AN100_JLT',
            '反应单元中间罐体积软测量'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.AN100, 'AN100', 'DB1S', 'AN100_JLT')
        "
      >
        {{ infoList.DB1S.AN100 }} L</p>
      <p class="shuzi fen shuzi9" @click="
          toCompon(
            2,
            'AN145',
            'DB1V',
            'AN145_JLT',
            '反应釜中间罐浓度测量值'
          )
        "
        @dblclick="
          Cclick(infoList.DB1V.AN145, 'AN145', 'DB1V', 'AN145_JLT')
        "
      >
        {{ infoList.DB1V.AN145 }}</p>
      <div class="rulie rulie1">{{infoList.DB1V.DG140 ? '解列': '入列'}}</div>
      <div class="rulie rulie2">{{infoList.DB1V.DG160 ? '解列': '入列'}}</div>
      <div class="rulie rulie3">{{infoList.DB1V.DG139 ? '解列': '入列'}}</div>
      <div class="rulie rulie4">{{infoList.DB1V.DG166 ? '解列': '入列'}}</div>
    </div>
    <div class="dingwei2">
      <p class="famen famen17">HV403</p>
      <p class="famen famen18">HV404</p>
      <p class="famen famen19">SV405</p>
      <p class="famen famen20">SV407</p>
      <p class="famen famen21">SV404</p>
      <p class="famen famen22">SV406</p>
      <p class="famen famen23">HV401</p>
      <p class="famen famen24">HV402</p>
      <p class="famen famen25">HV453</p>
      <p class="famen famen26">HV432</p>
      <p class="famen famen27">SV408</p>
      <p class="famen famen28">HV439</p>
      <p class="famen famen29">HV452</p>
      <p class="famen famen30">SV413</p>
      <p class="famen famen31">FV402</p>
      <p class="famen famen32">FV401</p>
      <p class="famen famen33">HV433</p>
      <p class="famen famen34">HV454</p>
      <p class="famen famen35">HV460</p>
      <p class="famen famen36">HV461</p>
      <p class="biaoshi hei biaoshi6">V401</p>
      <p class="wenzi wenzi13">1#原料罐</p>
      <p class="wenzi wenzi14">接氮气</p>
      <p class="wenzi fen wenzi15" @click="
          toCompon(
            2,
            'AN39',
            'DB1V',
            'AN39_JLT',
            '反应釜2#罐下液流量软测量'
          )
        "
        @dblclick="
          Cclick(infoList.DB1V.AN39, 'AN39', 'DB1V', 'AN39_JLT')
        "
      >流量 {{ infoList.DB1V.AN39 }}</p>
      <p class="wenzi fen wenzi16" @click="
          toCompon(
            2,
            'AN38',
            'DB1V',
            'AN38_JLT',
            '反应釜1#罐下液流量软测量'
          )
        "
        @dblclick="
          Cclick(infoList.DB1V.AN38, 'AN38', 'DB1V', 'AN38_JLT')
        "
      >流量 {{ infoList.DB1V.AN38 }}</p>
      <p class="wenzi fen wenzi17" @click="
          toCompon(
            2,
            'AN144',
            'DB1V',
            'AN144_JLT',
            '反应釜1#原料罐浓度测量'
          )
        "
        @dblclick="
          Cclick(infoList.DB1V.AN144, 'AN144', 'DB1V', 'AN144_JLT')
        "
      >
        浓度分析 {{ infoList.DB1V.AN144 }}</p>
      <p class="wenzi wenzi18">自精馏进料泵</p>
      <p class="shuzi fen shuzi10" @click="
          toCompon(
            2,
            'BL0002',
            'FYFTJ',
            'LI40101',
            '1#原料罐液位计算值'
          )
        "
        @dblclick="
          Cclick(infoList.FYFTJ.BL0002, 'BL0002', 'FYFTJ', 'LI40101')
        "
      >
        {{ infoList.FYFTJ.BL0002 }}L</p>
      <p class="shuzi fen shuzi11" @click="
          toCompon(
            2,
            'BL0003',
            'FYFTJ',
            'LI40201',
            '2#原料罐液位计算值'
          )
        "
        @dblclick="
          Cclick(infoList.FYFTJ.BL0003, 'BL0003', 'FYFTJ', 'LI40201')
        "
      >
        {{ infoList.FYFTJ.BL0003 }} L</p>
      <p class="shuzi shuzi12" @click="
          toCompon(
            2,
            'FI401',
            'DB1S',
            'FI401',
            '反应釜1#罐温度'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.FI401, 'FI401', 'DB1S', 'FI401')
        "
      >
        {{ infoList.DB1S.FI401 }} ℃</p>
      <p class="shuzi shuzi13" @click="
          toCompon(
            2,
            'LI401',
            'DB1S',
            'LI401',
            '1#原料罐液位'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.LI401, 'LI401', 'DB1S', 'LI401')
        "
      >
        {{ infoList.DB1S.LI401 }} %</p>
      <p class="shuzi shuzi14" @click="
          toCompon(
            2,
            'FV401_MV',
            'DB1S',
            'FV401_MV',
            '1#原料罐出口料液电动调节阀跟踪'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.FV401_MV, 'FV401_MV', 'DB1S', 'FV401_MV')
        "
      >
        {{ infoList.DB1S.FV401_MV }} %</p>
      <p class="shuzi shuzi15" @click="
          toCompon(
            2,
            'FV402_MV',
            'DB1S',
            'FV402_MV',
            '2#原料罐出口料液电动调节阀跟踪'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.FV402_MV, 'FV402_MV', 'DB1S', 'FV402_MV')
        "
      >
        {{ infoList.DB1S.FV402_MV }} %</p>
      <p class="shuzi shuzi31" @click="
          toCompon(
            2,
            'LI402',
            'DB1S',
            'LI402',
            '2#原料罐液位'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.LI402, 'LI402', 'DB1S', 'LI402')
        "
      >
        {{ infoList.DB1S.LI402 }} %</p>
      <p class="shuzi shuzi16">35 L</p>
      <p class="shuzi shuzi17">35 L</p>
      <div class="rulie rulie5">{{infoList.DB1V.DG146 ? '解列': '入列'}}</div>
      <div class="rulie rulie6">{{infoList.DB1V.DG148 ? '解列': '入列'}}</div>
      <div class="rulie rulie7">{{infoList.DB1V.DG145 ? '解列': '入列'}}</div>
      <div class="rulie rulie8">{{infoList.DB1V.DG147 ? '解列': '入列'}}</div>
      <div class="rulie rulie9">{{infoList.DB1V.DG149 ? '解列': '入列'}}</div>
      <div class="rulie rulie10">{{infoList.DB1V.DG165 ? '解列': '入列'}}</div>
    </div>
    <div class="dingwei3">
      <p class="famen famen37">HV427</p>
      <p class="famen famen38">HV408</p>
      <p class="famen famen39">FV403</p>
      <p class="famen famen40">FV404</p>
      <p class="famen famen41">HV423</p>
      <p class="famen famen42">SV401</p>
      <p class="famen famen43">HV441</p>
      <p class="famen famen44">HV442</p>
      <p class="famen famen45">HV425</p>
      <p class="famen famen46">HV424</p>
      <p class="famen famen47">HV440</p>
      <p class="famen famen48">SV402</p>
      <p class="famen famen49">HV426</p>
      <p class="famen famen50">HV436</p>
      <p class="famen famen51">HV461</p>
      <p class="famen famen52">HV416</p>
      <p class="biaoshi hei biaoshi7">R401</p>
      <p class="wenzi fen wenzi19">酸值计算</p>
      <p class="wenzi fen wenzi20">酸值</p>
      <p class="wenzi fen wenzi21">酯化率</p>
      <p class="wenzi wenzi22">间歇反应釜</p>
      <p class="wenzi wenzi23">至废水箱</p>
      <p class="wenzi wenzi24">自储气罐</p>
      <p class="wenzi wenzi25">至废水箱</p>
      <p class="wenzi wenzi26">-------------------- =</p>
      <p class="wenzi wenzi27">*</p>
      <p class="shuzi fen shuzi18" @click="
          toCompon(
            2,
            'AN324',
            'DB1V',
            'AN324_JLT',
            '酯化率'
          )
        "
        @dblclick="
          Cclick(infoList.DB1V.AN324, 'AN324', 'DB1V', 'AN324_JLT')
        "
      >
        {{ infoList.DB1V.AN324 }}</p>
      <p class="shuzi fen shuzi19" @click="
          toCompon(
            2,
            'AN316',
            'DB1V',
            'AN316_JLT',
            '酸值计算中滴定用碱量'
          )
        "
        @dblclick="
          Cclick(infoList.DB1V.AN316, 'AN316', 'DB1V', 'AN316_JLT')
        "
      >
        {{ infoList.DB1V.AN316 }}</p>
      <p class="shuzi fen shuzi20" @click="
          toCompon(
            2,
            'AN317',
            'DB1V',
            'AN317_JLT',
            '酸值计算中取样量'
          )
        "
        @dblclick="
          Cclick(infoList.DB1V.AN317, 'AN317', 'DB1V', 'AN317_JLT')
        "
      >
        {{ infoList.DB1V.AN317 }}</p>
      <p class="shuzi fen shuzi21" @click="
          toCompon(
            2,
            'AN143',
            'DB1V',
            'AN143_JLT',
            '反应釜酸值分析'
          )
        "
        @dblclick="
          Cclick(infoList.DB1V.AN143, 'AN143', 'DB1V', 'AN143_JLT')
        "
      >
        {{ infoList.DB1V.AN143 }}</p>
      <p class="shuzi fen shuzi22" @click="
          toCompon(
            2,
            'AN243',
            'DB1V',
            'AN243_JLT',
            '反应单元反应釜体积软测量值'
          )
        "
        @dblclick="
          Cclick(infoList.DB1V.AN243, 'AN243', 'DB1V', 'AN243_JLT')
        "
      >
        {{ infoList.DB1V.AN243 }} L</p>
      <p class="shuzi shuzi23" @click="
          toCompon(
            2,
            'FV403_MV',
            'DB1S',
            'FV403_MV',
            '中间罐料液去釜内电动调节阀跟踪'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.FV403_MV, 'FV403_MV', 'DB1S', 'FV403_MV')
        "
      >
        {{ infoList.DB1S.FV403_MV }} %</p>
      <p class="shuzi shuzi24" @click="
          toCompon(
            2,
            'FV404_MV',
            'DB1S',
            'FV404_MV',
            '中间罐料液去产品罐电动调节阀跟踪'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.FV404_MV, 'FV404_MV', 'DB1S', 'FV404_MV')
        "
      >
        {{ infoList.DB1S.FV404_MV }} %</p>
      <p class="shuzi shuzi25" @click="
          toCompon(
            2,
            'SI401',
            'DB1S',
            'SI401',
            '反应釜搅拌桨转速'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.SI401, 'SI401', 'DB1S', 'SI401')
        "
      >
        {{ infoList.DB1S.SI401 }} r/min</p>
      <p class="shuzi shuzi26" @click="
          toCompon(
            2,
            'TI501',
            'DB1S',
            'TI501',
            '1#水箱温度'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.TI501, 'TI501', 'DB1S', 'TI501')
        "
      >
        {{ infoList.DB1S.TI501 }} A</p>
      <p class="shuzi shuzi27" @click="
          toCompon(
            2,
            'PI401',
            'DB1S',
            'PI401',
            '釜内蒸汽压力'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.PI401, 'PI401', 'DB1S', 'PI401')
        "
      >
        {{ infoList.DB1S.PI401 }} Kpa</p>
      <p class="shuzi shuzi28">56.11</p>
      <p class="shuzi shuzi29" @click="
          toCompon(
            2,
            'LI403',
            'DB1S',
            'LI403',
            '釜内料液液位'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.LI403, 'LI403', 'DB1S', 'LI403')
        "
      >
        {{ infoList.DB1S.LI403 }} %</p>
      <p class="shuzi shuzi30" @click="
          toCompon(
            2,
            'TI404',
            'DB1S',
            'TI404',
            '釜夹套温度'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.TI404, 'TI404', 'DB1S', 'TI404')
        "
      >
        {{ infoList.DB1S.TI404 }} ℃</p>
      <div class="rulie rulie11">{{infoList.DB1V.DG142 ? '解列': '入列'}}</div>
      <div class="rulie rulie12">{{infoList.DB1V.DG143 ? '解列': '入列'}}</div>
      <div class="rulie rulie13">{{infoList.DB1V.DG144 ? '解列': '入列'}}</div>
    </div>
    <div class="dingwei4">
      <p class="famen famen53">SV410</p>
      <p class="famen famen54">HV421</p>
      <p class="famen famen55">HV438</p>
      <p class="famen famen56">HV43402</p>
      <p class="famen famen57">HV43401</p>
      <p class="famen famen58">HV435</p>
      <p class="famen famen59">HV412</p>
      <p class="famen famen60">SV409</p>
      <p class="famen famen61">HV414</p>
      <p class="famen famen62">HV413</p>
      <p class="famen famen63">HV410</p>
      <p class="famen famen64">HV418</p>
      <p class="famen famen65">FV406</p>
      <p class="famen famen66">HV464</p>
      <p class="famen famen67">SV403</p>
      <p class="famen famen68">HV409</p>
      <p class="famen famen69">HV437</p>
      <p class="famen famen70">HV467</p>
      <p class="famen famen71">HV415</p>
      <p class="famen famen72">HV411</p>
      <p class="famen famen73">SV412</p>
      <p class="biaoshi bai biaoshi8">P401</p>
      <p class="biaoshi hei biaoshi9">V404</p>
      <p class="wenzi wenzi28">蒸汽温度压力</p>
      <p class="wenzi wenzi29">蒸汽</p>
      <p class="wenzi wenzi30">自换热单元</p>
      <p class="wenzi wenzi31" @click="
          toCompon(
            2,
            'TI001_DCS',
            'DB1S',
            'TI001',
            '公用水箱温度'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.TI001_DCS, 'TI001_DCS', 'DB1S', 'TI001')
        "
      >
        温度 {{ infoList.DB1S.TI001_DCS }} ℃</p>
      <p class="wenzi wenzi32" @click="
          toCompon(
            2,
            'PI302',
            'DB1S',
            'PI302',
            '水泵出口压力'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.PI302, 'PI302', 'DB1S', 'PI302')
        "
      >压力 {{ infoList.DB1S.PI302 }} Kpa</p>
      <p class="wenzi wenzi33">蒸汽压力</p>
      <p class="wenzi wenzi34">电加热</p>
      <p class="wenzi wenzi35">产品罐</p>
      <p class="wenzi wenzi36">去废水罐</p>
      <p class="wenzi fen wenzi37">溶液体积</p>
      <p class="wenzi fen wenzi38">流量</p>
      <p class="wenzi fen wenzi39">浓度分析</p>
      <p class="wenzi wenzi40">50 L</p>
      <p class="shuzi fen shuzi32" @click="
          toCompon(
            2,
            'AN88',
            'DB1V',
            'AN88_JLT',
            '反应釜产品罐体积软测量值单位L'
          )
        "
        @dblclick="
          Cclick(infoList.DB1V.AN88, 'AN88', 'DB1V', 'AN88_JLT')
        "
      >
        {{ infoList.DB1V.AN88 }}</p>
      <p class="shuzi fen shuzi33" @click="
          toCompon(
            2,
            'AN89',
            'DB1V',
            'AN89_JLT',
            '反应釜产品罐流量软测量值'
          )
        "
        @dblclick="
          Cclick(infoList.DB1V.AN89, 'AN89', 'DB1V', 'AN89_JLT')
        "
      >
        {{ infoList.DB1V.AN89 }}</p>
      <p class="shuzi fen shuzi34" @click="
          toCompon(
            2,
            'AN146',
            'DB1V',
            'AN146_JLT',
            '反应釜产品罐浓度分析'
          )
        "
        @dblclick="
          Cclick(infoList.DB1V.AN146, 'AN146', 'DB1V', 'AN146_JLT')
        "
      >
        {{ infoList.DB1V.AN146 }}</p>
      <p class="shuzi shuzi35" @click="
          toCompon(
            2,
            'BP402_MV',
            'DB1S',
            'BP402_MV',
            '反应釜搅拌变频跟踪'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.BP402_MV, 'BP402_MV', 'DB1S', 'BP402_MV')
        "
      >
        {{ infoList.DB1S.BP402_MV }} %</p>
      <p class="shuzi shuzi36" @click="
          toCompon(
            2,
            'TI403',
            'DB1S',
            'TI403',
            '气相温度'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.TI403, 'TI403', 'DB1S', 'TI403')
        "
      >
        {{ infoList.DB1S.TI403 }} ℃</p>
      <p class="shuzi shuzi37" @click="
          toCompon(
            2,
            'TI402',
            'DB1S',
            'TI402',
            '液相温度'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.TI402, 'TI402', 'DB1S', 'TI402')
        "
      >
        {{ infoList.DB1S.TI402 }} ℃</p>
      <p class="shuzi shuzi38" @click="
          toCompon(
            2,
            'PI104',
            'DB1S',
            'PI104',
            '2#再沸器进口蒸汽压力'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.PI104, 'PI104', 'DB1S', 'PI104')
        "
      >
        {{ infoList.DB1S.PI104 }} Kpa</p>
      <p class="shuzi shuzi39" @click="
          toCompon(
            2,
            'FI402',
            'DB1S',
            'FI402',
            '回流泵至产品罐流量'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.FI402, 'FI402', 'DB1S', 'FI402')
        "
      >
        {{ infoList.DB1S.FI402 }} L/h</p>
      <p class="shuzi shuzi40" @click="
          toCompon(
            2,
            'TI314',
            'DB1S',
            'TI314',
            '分汽包蒸汽出口温度'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.TI314, 'TI314', 'DB1S', 'TI314')
        "
      >
        {{ infoList.DB1S.TI314 }} ℃</p>
      <p class="shuzi shuzi41" @click="
          toCompon(
            2,
            'PI305',
            'DB1S',
            'PI305',
            '分汽包压力'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.PI305, 'PI305', 'DB1S', 'PI305')
        "
      >
        {{ infoList.DB1S.PI305 }} Kpa</p>
      <p class="shuzi shuzi42" @click="
          toCompon(
            2,
            'FV302_MV',
            'DB1S',
            'FV302_MV',
            '蒸汽压力调节阀_DCS优化输出'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.FV302_MV, 'FV302_MV', 'DB1S', 'FV302_MV')
        "
      >
        {{ infoList.DB1S.FV302_MV }} %</p>
      <p class="shuzi shuzi43" @click="
          toCompon(
            2,
            'TZ402_MV',
            'DB1S',
            'TZ402_MV',
            '反应釜加热器跟踪'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.TZ402_MV, 'TZ402_MV', 'DB1S', 'TZ402_MV')
        "
      >
        {{ infoList.DB1S.TZ402_MV }} %</p>
      <p class="shuzi shuzi44" @click="
          toCompon(
            2,
            'FV406_MV',
            'DB1S',
            'FV406_MV',
            '釜夹套进水气动调节阀跟踪'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.FV406_MV, 'FV406_MV', 'DB1S', 'FV406_MV')
        "
      >
        {{ infoList.DB1S.FV406_MV }} %</p>
      <p class="shuzi shuzi45" @click="
          toCompon(
            2,
            'LI405',
            'DB1S',
            'LI405',
            '产品罐液位'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.LI405, 'LI405', 'DB1S', 'LI405')
        "
      >
        {{ infoList.DB1S.LI405 }} %</p>
      <p class="shuzi shuzi46" @click="
          toCompon(
            2,
            'BP401_MV',
            'DB1S',
            'BP401_MV',
            '回流泵变频跟踪'
          )
        "
        @dblclick="
          Cclick(infoList.DB1S.BP401_MV, 'BP401_MV', 'DB1S', 'BP401_MV')
        "
      >
        {{ infoList.DB1S.BP401_MV }} %</p>
      <div class="rulie rulie14">{{infoList.DB1V.DG159 ? '解列': '入列'}}</div>
      <div class="rulie rulie15">{{infoList.DB1V.DG141 ? '解列': '入列'}}</div>
      <div class="rulie rulie16">{{infoList.DB1V.DG158 ? '解列': '入列'}}</div>
      <div class="rulie rulie17">{{infoList.DB1V.DG138 ? '解列': '入列'}}</div>
    </div>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    ></Manual>
  </div>
</template>
<script>
import index from "./_index/index.js";
import Manual from "@/components/Manual.vue"; //手操器组件
//import Evaluate from "@/components/Evaluate.vue"; //运行评价组件
//import Security from "@/components/Security.vue"; //安全设置组件
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  name: "d6fyfIndex",
  components: {
    Manual,
    Firstcontrol,
    Historical,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      maskbok: false,
      JRLcysz: false,
      isMshow: false,
      isHshow: false,
      Aname: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      canshu1: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      flag: 0,
      isComShow: false,
      fullscreenLoading: false,
      grouptime: null,
      spotArr: [],
      projectData: "",
    };
  },
  mixins: [index],
  created() {
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },

      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
  },
  // filters: {
  //   // 阀门红绿灯
  //   typeFifter: (val) => {
  //     if (val < 5) {
  //       return require("@/assets/images/截止阀.png");
  //     } else if (val >= 5) {
  //       return require("@/assets/images/截止阀2.png");
  //     }
  //   },
  //   //釜内红绿灯
  //   tyFifter: (val) => {
  //     if (val <= 10) {
  //       return require("@/assets/images/关闭.png");
  //     } else if (val > 10) {
  //       return require("@/assets/images/开始.png");
  //     }
  //   },
  // },
};
</script>
<style lang="scss" scoped>
.dabox {
  box-sizing: border-box;
  width: 83%;
  height: 86vh;
  margin-left: 10vw;
  //   margin-top: 2vh;
  background-image: url("~@/assets/images/间歇反应.png");
  background-size: 100% 100%;
  display: flex;
  flex-wrap: wrap;
  .dingwei1,
  .dingwei2,
  .dingwei3,
  .dingwei4 {
    position: relative;
    font-size: 0.9vw;
    width: 50%;
    height: 50%;
    //  background-color: pink;
    .famen {
      color: #fff;
      font-size: 0.7vw;
      position: absolute;
    }
    .famen1 {
      top: 2.4vh;
      left: 11.5vw;
    }
    .famen2 {
      top: 2vh;
      left: 19.5vw;
    }
    .famen3 {
      top: 12vh;
      right: 11.5vw;
    }
    .famen4 {
      top: 17.3vh;
      right: 11.7vw;
    }
    .famen5 {
      top: 19.2vh;
      right: 15.7vw;
    }
    .famen6 {
      top: 19.2vh;
      left: 20.1vw;
    }
    .famen7 {
      top: 19.2vh;
      left: 16.4vw;
    }
    .famen8 {
      top: 19.5vh;
      left: 5vw;
    }
    .famen9 {
      bottom: 9.7vh;
      left: 9vw;
    }
    .famen10 {
      bottom: 6vh;
      left: 2.4vw;
    }
    .famen11 {
      bottom: 0.6vh;
      left: 16.8vw;
    }
    .famen12 {
      bottom: 13vh;
      left: 16.4vw;
    }
    .famen13 {
      bottom: 5.2vh;
      right: 7.7vw;
    }
    .famen14 {
      bottom: 5.2vh;
      right: 3.3vw;
    }
    .famen15 {
      bottom: 11.5vh;
      right: 3.1vw;
    }
    .famen16 {
      top: 8.5vh;
      right: 4.7vw;
    }
    .biaoshi {
      font-weight: 700;
      font-size: 1vw;
      position: absolute;
    }
    .bai {
      color: #fff;
    }
    .hei {
      color: #000;
    }
    .biaoshi1 {
      top: 5vh;
      left: 12.5vw;
    }
    .biaoshi2 {
      bottom: 12vh;
      right: 11.3vw;
    }
    .biaoshi3 {
      bottom: 8.5vh;
      right: 11.3vw;
    }
    .biaoshi4 {
      top: 16vh;
      right: -0.5vw;
    }
    .biaoshi5 {
      bottom: 3.4vh;
      left: 6.3vw;
    }
    .wenzi {
      font-size: 0.7vw;
      color: #fff;
      position: absolute;
    }

    .shuzi {
      position: absolute;
      font-size: 0.7vw;
      color: #fff;cursor: pointer;
    }
    .fen {
      color: #ff09db;
    }
    .wenzi1 {
      top: 1vh;
      left: 5.7vw;
    }
    .wenzi2 {
      top: 7.5vh;
      left: 12vw;
    }
    .wenzi3 {
      bottom: 7.4vh;
      right: 10.8vw;
    }
    .wenzi4 {
      bottom: 18.7vh;
      right: 6.5vw;
    }
    .wenzi5 {
      bottom: 14vh;
      left: 2.5vw;
    }
    .wenzi6 {
      bottom: 12vh;
      left: 2.5vw;
    }
    .wenzi7 {
      top: 8vh;
      right: 11vw;
    }
    .wenzi8 {
      top: 19vh;
      right: -0.7vw;
      color: #000;
    }
    .wenzi9 {
      bottom: -1vh;
      left: 7vw;
      color: #000;
      writing-mode: vertical-rl;
      text-align: center;
      white-space: nowrap;
    }
    .wenzi10 {
      bottom: 1.2vh;
      right: -0.2vw;
    }
    .wenzi11 {
      bottom: 11vh;
      right: 9.6vw;
      color: #000;
      writing-mode: vertical-rl;
      text-align: center;
      white-space: nowrap;
    }
    .wenzi12 {
      bottom: 3vh;
      left: 9.5vw;
    }
    .shuzi1 {
      top: 10vh;
      left: 3vw;
    }
    .shuzi2 {
      top: 21.5vh;
      left: 20vw;
    }
    .shuzi3 {
      top: 23vh;
      left: 4.5vw;
    }
    .shuzi4 {
      top: 12.5vh;
      left: 15vw;
    }
    .shuzi5 {
      bottom: 20.5vh;
      right: 6.3vw;
    }
    .shuzi6 {
      bottom: 16.5vh;
      right: 6.3vw;
    }
    .shuzi7 {
      bottom: 0vh;
      left: 3vw;
    }
    .shuzi8 {
      bottom: 2vh;
      left: 3.5vw;
    }
    .shuzi9 {
      bottom: 1vh;
      left: 10.5vw;
    }
    .famen17 {
      top: 2vh;
      left: 0.2vw;
    }
    .famen18 {
      top: 9.5vh;
      left: 0.2vw;
    }
    .famen19 {
      top: 3vh;
      left: 11vw;
    }
    .famen20 {
      top: 8.9vh;
      left: 11vw;
    }
    .famen21 {
      top: 3vh;
      left: 18vw;
    }
    .famen22 {
      top: 8.9vh;
      left: 18vw;
    }
    .famen23 {
      top: 2vh;
      right: 13.8vw;
    }
    .famen24 {
      top: 9.5vh;
      right: 13.8vw;
    }
    .famen25 {
      top: 0vh;
      right: 8.8vw;
    }
    .famen26 {
      top: 8.2vh;
      right: 7vw;
    }
    .famen27 {
      bottom: 13vh;
      left: 1vw;
    }
    .famen28 {
      bottom: 12.5vh;
      left: 5.7vw;
    }
    .famen29 {
      bottom: 5vh;
      left: 5.4vw;
    }
    .famen30 {
      bottom: 5.3vh;
      left: 9.3vw;
    }
    .famen31 {
      bottom: 19vh;
      left: 10.3vw;
    }
    .famen32 {
      bottom: 19vh;
      left: 18.3vw;
    }
    .famen33 {
      bottom: 6.2vh;
      left: 18vw;
    }
    .famen34 {
      bottom: 4vh;
      right: 5.5vw;
    }
    .famen35 {
      bottom: 9.3vh;
      right: 5.5vw;
    }
    .famen36 {
      bottom: 13.5vh;
      right: 13.2vw;
    }
    .biaoshi6 {
      bottom: 23vh;
      right: 12vw;
    }
    .wenzi13 {
      bottom: 21vh;
      right: 11.9vw;
      color: #000;
    }
    .wenzi14 {
      bottom: 6.5vh;
      left: 11.5vw;
    }
    .wenzi15 {
      bottom: 17vh;
      left: 5.5vw;
    }
    .wenzi16 {
      bottom: 17vh;
      right: 15.5vw;
    }
    .wenzi17 {
      bottom: 17vh;
      right: 5vw;
    }
    .wenzi18 {
      bottom: 7vh;
      right: -1vw;
    }
    .shuzi10 {
      top: 16.5vh;
      right: 5vw;
    }
    .shuzi11 {
      top: 16.5vh;
      left: 6vw;
    }
    .shuzi12 {
      top: 16.5vh;
      right: 20vw;
    }
    .shuzi13 {
      top: 18.5vh;
      right: 4.5vw;
    }
    .shuzi14 {
      bottom: 13.5vh;
      right: 21vw;
    }
    .shuzi15 {
      bottom: 13.5vh;
      left: 10vw;
    }
    .shuzi16 {
      bottom: 17vh;
      left: 2.5vw;
    }
    .shuzi17 {
      bottom: 17vh;
      right: 11vw;
    }
    .famen37 {
      top: 6vh;
      left: 7.8vw;
    }
    .famen38 {
      top: 4.8vh;
      left: 10.6vw;
    }
    .famen39 {
      top: 7.5vh;
      left: 20.9vw;
    }
    .famen40 {
      top: 4.5vh;
      right: 14.6vw;
    }
    .famen41 {
      top: 19.8vh;
      left: 4.1vw;
    }
    .famen42 {
      bottom: 12vh;
      left: 5.2vw;
    }
    .famen43 {
      bottom: 10.3vh;
      left: 10vw;
    }
    .famen44 {
      bottom: 14vh;
      left: 17.7vw;
    }
    .famen45 {
      bottom: 11.3vh;
      right: 15.8vw;
    }
    .famen46 {
      bottom: 11.3vh;
      right: 8.2vw;
    }
    .famen47 {
      bottom: 15.8vh;
      right: 11.6vw;
    }
    .famen48 {
      bottom: 17.5vh;
      right: 2.8vw;
    }
    .famen49 {
      bottom: 8.3vh;
      right: 12.3vw;
    }
    .famen50 {
      bottom: 6.2vh;
      right: 8vw;
    }
    .famen51 {
      bottom: 6.2vh;
      right: 2.9vw;
    }
    .famen52 {
      bottom: 1.7vh;
      right: 16.2vw;
    }
    .biaoshi7 {
      top: 10vh;
      right: -0.4vw;
    }
    .wenzi19 {
      top: 20vh;
      left: 12vw;
    }
    .wenzi26 {
      top: 19.8vh;
      left: 16vw;
    }
    .wenzi20 {
      top: 17.5vh;
      right: 16vw;
    }
    .wenzi21 {
      top: 17.5vh;
      right: 12vw;
    }
    .wenzi22 {
      top: 13.5vh;
      right: 0.3vw;
      color: #000;
      writing-mode: vertical-rl;
      text-align: center;
      white-space: nowrap;
    }
    .wenzi23 {
      bottom: 10.2vh;
      left: 15.5vw;
    }
    .wenzi24 {
      bottom: 15.9vh;
      left: 7.8vw;
    }
    .wenzi25 {
      bottom: 3.4vh;
      left: -3vw;
    }
    .wenzi27 {
      top: 18.8vh;
      left: 18.7vw;
    }
    .shuzi18 {
      top: 20vh;
      right: 12.5vw;
    }
    .shuzi19 {
      top: 18.5vh;
      left: 16.5vw;
    }
    .shuzi20 {
      top: 21vh;
      left: 18.5vw;
    }
    .shuzi21 {
      top: 20vh;
      right: 16vw;
    }
    .shuzi22 {
      top: 20vh;
      right: 7.5vw;
    }
    .shuzi23 {
      top: 9.5vh;
      left: 20.9vw;
    }
    .shuzi24 {
      top: -1vh;
      right: 14.4vw;
    }
    .shuzi25 {
      top: -1.5vh;
      right: 4.8vw;
    }
    .shuzi26 {
      top: 1vh;
      right: 5.5vw;
    }
    .shuzi27 {
      top: 10.5vh;
      right: 5.5vw;
    }
    .shuzi28 {
      top: 18.5vh;
      left: 20vw;
    }
    .shuzi29 {
      top: 17.5vh;
      right: 7.5vw;
    }
    .shuzi30 {
      top: 22.5vh;
      right: 7.5vw;
    }
    .shuzi31 {
      top: 19vh;
      left: 6vw;
    }
    .famen53 {
      top: 4.5vh;
      left: 4.7vw;
    }
    .famen54 {
      top: 5vh;
      left: 10.7vw;
    }
    .famen55 {
      top: -0.5vh;
      right: 5.2vw;
    }
    .famen56 {
      top: 9.5vh;
      right: 20.2vw;
    }
    .famen57 {
      top: 9.5vh;
      right: 16.8vw;
    }
    .famen58 {
      top: 12vh;
      left: 11.2vw;
    }
    .famen59 {
      top: 13vh;
      right: 14.8vw;
    }
    .famen60 {
      top: 19vh;
      left: 11.8vw;
    }
    .famen61 {
      top: 19vh;
      left: 18vw;
    }
    .famen62 {
      top: 19.5vh;
      right: 15.5vw;
    }
    .famen63 {
      top: 17.3vh;
      right: 5vw;
    }
    .famen64 {
      bottom: 17.3vh;
      left: 3.3vw;
    }
    .famen65 {
      bottom: 17.3vh;
      left: 7.6vw;
    }
    .famen66 {
      bottom: 14vh;
      left: 3.3vw;
    }
    .famen67 {
      bottom: 11vh;
      left: 1vw;
    }
    .famen68 {
      bottom: 6vh;
      left: 0vw;
    }
    .famen69 {
      bottom: 4.5vh;
      left: 9.4vw;
    }
    .famen70 {
      bottom: 6.5vh;
      left: 15.7vw;
    }
    .famen71 {
      bottom: 6vh;
      right: 18.5vw;
    }
    .famen72 {
      bottom: 13vh;
      right: 5vw;
    }
    .famen73 {
      bottom: 14.5vh;
      right: 1vw;
    }
    .biaoshi8 {
      bottom: 1vh;
      right: 9vw;
    }
    .biaoshi9 {
      bottom: 11.7vh;
      right: 20vw;
    }
    .wenzi28 {
      top: -1vh;
      right: -1vw;
    }
    .wenzi29 {
      top: 6.5vh;
      right: 1vw;
    }
    .wenzi30 {
      top: 8.5vh;
      right: -0.1vw;
    }
    .wenzi31 {
      top: -0.8vh;
      left: 16vw;
    }
    .wenzi32 {
      top: 1vh;
      left: 16vw;
    }
    .wenzi33 {
      top: 2vh;
      right: 16vw;
    }
    .wenzi34 {
      bottom: 17vh;
      left: -0.5vw;
    }
    .wenzi35 {
      bottom: 14.5vh;
      right: 20.3vw;
      color: #000;
    }
    .wenzi36 {
      bottom: 8.5vh;
      right: -2vw;
    }
    .wenzi37 {
      bottom: 12vh;
      right: 14vw;
    }
    .wenzi38 {
      bottom: 12vh;
      right: 11vw;
    }
    .wenzi39 {
      bottom: 7.5vh;
      right: 14vw;
    }
    .wenzi40 {
      bottom: 9vh;
      right: 19vw;
    }
    .shuzi32 {
      bottom: 10vh;
      right: 14.5vw;
    }
    .shuzi33 {
      bottom: 10vh;
      right: 11vw;
    }
    .shuzi34 {
      bottom: 5.5vh;
      right: 14.5vw;
    }
    .shuzi35 {
      top: 1.5vh;
      left: 3.3vw;
    }
    .shuzi36 {
      top: 11.5vh;
      left: 3.3vw;
    }
    .shuzi37 {
      top: 17vh;
      left: 3.3vw;
    }
    .shuzi38 {
      top: 4vh;
      right: 15.7vw;
    }
    .shuzi39 {
      top: -2.5vh;
      right: 4.7vw;
    }
    .shuzi40 {
      top: 1.5vh;
      right: 0vw;
    }
    .shuzi41 {
      top: 3.5vh;
      right: -0.4vw;
    }
    .shuzi42 {
      top: 9.5vh;
      right: 5.5vw;
    }
    .shuzi43 {
      bottom: 15vh;
      left: -0.5vw;
    }
    .shuzi44 {
      bottom: 15.5vh;
      left: 7.5vw;
    }
    .shuzi45 {
      bottom: 15vh;
      right: 14.5vw;
    }
    .shuzi46 {
      bottom: 3vh;
      right: 1vw;
    }
    .rulie {
      position: absolute;
      color: #000;
      background-color: #fff;
      cursor: pointer; /* 指针形状 */
      width: 2vw;
      height: 2vh;
      font-size: 0.7vw;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .rulie1 {
      bottom: 16.5vh;
      right: 3.2vw;
    }
    .rulie2 {
      bottom: 9.5vh;
      right: 3vw;
    }
    .rulie3 {
      bottom: 1vh;
      right: 2.5vw;
    }
    .rulie4 {
      bottom: 1vh;
      right: 11.7vw;
    }
    .rulie5 {
      top: 3vh;
      left: 6.4vw;
    }
    .rulie6 {
      top: 6.5vh;
      left: 6.4vw;
    }
    .rulie7 {
      top: 1vh;
      left: 18vw;
    }
    .rulie8 {
      top: 6.5vh;
      left: 18vw;
    }
    .rulie9 {
      bottom: 8.3vh;
      left: 0.3vw;
    }
    .rulie10 {
      bottom: 9vh;
      left: 11vw;
    }
    .rulie11 {
      bottom: 10vh;
      left: 3.5vw;
    }
    .rulie12 {
      bottom: 16.2vh;
      right: 7.5vw;
    }
    .rulie13 {
      bottom: 12vh;
      right: 1.9vw;
    }
    .rulie14 {
      top: 6.5vh;
      left: 7vw;
    }
    .rulie15 {
      bottom: 9vh;
      left: 0.7vw;
    }
    .rulie16 {
      bottom: 18vh;
      left: 14vw;
    }
    .rulie17 {
      bottom: 7vh;
      right: 5vw;
    }
  }
}
</style>